import ReactGtag from '../../lib/react-gtag/index'

class Analytics {

    static goorderAnalyticsId = null;
    static shopAnalyticsId = null;
    static googleAnalyticIds = [];

    static initGA(goorderAnalyticsId) {
        this.goorderAnalyticsId = goorderAnalyticsId;
        ReactGtag.initialize(goorderAnalyticsId, {
            send_page_view: false
        });
    }

    static addGA(googleAnalyticsId) {
        if (googleAnalyticsId.includes("GTM-")) {
            ReactGtag.initializeTagManager(googleAnalyticsId)
            this.googleAnalyticIds.push(googleAnalyticsId);
        } else {
            this.shopAnalyticsId = googleAnalyticsId;
            this.googleAnalyticIds.push(googleAnalyticsId);
            ReactGtag.gtag('config', googleAnalyticsId, {
                send_page_view: false,
            });
        }
    }

    static pageView(path) {
        ReactGtag.gtag('event', 'page_view', {
            'page_title': path,
            'page_path': path
        });
    }

    static purchase(order) {
        var items = [];
        for (var i = 0; i < order.items.length; i++) {
            var orderDataItem = order.items[i];
            var item = {
                id: orderDataItem.id,
                name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                currency: orderDataItem.total_money.currency,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var purchaseData = {
            "transaction_id": order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "tax": 1.23,
            "shipping": 0,
            // "tax": 1.24,
            // "shipping": data.shipmentPrice,
            "items": items,
        };
        ReactGtag.gtag('event', 'purchase', purchaseData);
    }

    static async addToCart(orderDataItem) {

        let data = {
            currency: "PLN",
            value: orderDataItem.total_price,
            items: [
                {
                    id: orderDataItem.item_id,
                    name: orderDataItem.name,
                    price: orderDataItem.total_price,
                    quantity: orderDataItem.quantity
                }
            ]
        }

        ReactGtag.ecommerce('add_to_cart', data);
    }

    static async removeFromCart(orderDataItem) {

        let value = orderDataItem.total_money ? orderDataItem.total_money.amount : undefined;
        let data = {
            currency: "PLN",
            value: value,
            items: [
                {
                    id: orderDataItem.item_id,
                    name: orderDataItem.name,
                    price: value,
                    quantity: orderDataItem.quantity
                }
            ]
        }

        ReactGtag.ecommerce('remove_from_cart', data);
    }

    static async viewItem(item) {

        let value = item.price ? item.price.amount : undefined;
        let data = {
            currency: 'PLN',
            value: value,
            items: [{
                item_id: item.id,
                item_name: item.name,
                value
            }]
        }

        ReactGtag.ecommerce('view_item', data);
    }


    static async selectItem(item) {

        let value = item.price ? item.price.amount : undefined;
        let data = {
            currency: 'PLN',
            value: value,
            items: [{
                item_id: item.id,
                item_name: item.name,
                value
            }]
        }

        ReactGtag.ecommerce('select_item', data);
    }

    static async addToCartNewOrder(order) {
        var items = [];
        for (var i = 0; i < order.items.length; i++) {
            var orderDataItem = order.items[i];
            var item = {
                item_id: orderDataItem.item_id,
                item_name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var data = {
            "transaction_id": order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "tax": 1.23,
            "items": items,
        };
        ReactGtag.ecommerce('add_to_cart', data);
    }
    static async beginCheckout(cart) {
        if(!cart) return;
        var items = [];
        for (var i = 0; i < cart.items.length; i++) {
            var orderDataItem = cart.items[i];
            var item = {
                item_id: orderDataItem.item_id,
                item_name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var data = {
            "transaction_id": cart.id,
            // "affiliation": "Google online store",
            "value": cart.total.amount,
            "currency": cart.total.currency,
            "tax": 1.23,
            "items": items,
        };
        ReactGtag.ecommerce('begin_checkout', data);
    }

}

export default Analytics;