import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux'

import {
    getAvailabilities,
    getCategories,
    getItemGroups,
    getItems,
    getItemsError,
    getMenuPending,
    getMenus,
    getPromotions,
    isAlcohol
} from '../../../application/menu/reducer';
import {Sticky, StickyContainer} from "react-sticky";
import CartButton from "../../common/CartButton";
import {Route} from "react-router-dom";
import MenuAddItem from './menu/MenuAddItem';
import LoadingContainer from "../../common/LoadingContainer";
import ErrorView from "../../common/ErrorView";
import {getPageComponents, getStore, isPreview} from "../../../application/store/reducer";
import StoreInfo from "./components/StoreInfo";
import CartSticky from "../cart/CartSticky";
import Footer from "../../layout/Footer";
import Test from "../Test";
import MenuMenus from "./components/MenuMenus";
import {ScrollToTop} from "../../utils/ScrollToTop";
import ItemHelper from "../../../application/common/ItemHelper";
import {getCart, getOrderType, getPickDate} from "../../../application/cart/reducer";
import AppConfig from "../../../application/common/AppConfig";
import MenuItemsGrid from "./components/MenuItemsGrid";
import MenuCategoryNav from "./components/MenuCategoryNav";
import MenuItemsList from "./components/MenuItemsList";
import DateService from "../../../application/service/DateService";
import AvailableHelper from "../../../application/common/AvailableHelper";
import ChangePickDateModal from "../../common/ChangePickDateModal";
import classNames from 'classnames';
import {withTranslation} from "react-i18next";
import MenuHelper from "../../../application/common/MenuHelper";
import Languages from "../../common/Languages";
import MenuPromotions from "./components/MenuPromotions";
import MenuPromotionsCategory from "./components/MenuPromotionsCategory";
import PromotionHelper from "../../../application/common/PromotionHelper";
import ItemViewHelper from "../../../application/common/ItemViewHelper";
import AgeRestrictedModal from "../../common/AgeRestrictedModal";
import AgeRestrictedContext from "../../../application/common/AgeRestrictedContext";
import analytics from "../../../application/common/Analytics";
import Analytics from "../../../application/common/Analytics";


const Menu = (props)=>{

    const [showAgeRestricted, setShowAgeRestricted] = useState(!AgeRestrictedContext.isAccepted() && AgeRestrictedContext.isAlcohol());
    const [showPickDateModal, setShowPickDateModal] = useState(false);
    const [search, setSearch] = useState(undefined);
    const myRef = useRef(null);
    const {items, itemGroups, categories, error, menus, match, availabilities, pick_date, orderType, preview, t} = props;

    useEffect(() => {
        let ageRestrictedAccepted = AgeRestrictedContext.isAccepted();
        if(showAgeRestricted && (ageRestrictedAccepted || !AgeRestrictedContext.isAlcohol())){
              setShowAgeRestricted(false)
        }
    }, [])
    const goToCart = (id) => {
        props.history.push('/cart');
    }
    const addItem = (id, categoryId, menuId)=>{
        let url = '/menu/' + menuId + '/add/' + id;
        // this.props.history.replace(url);
        props.history.push(url, {"back": true});
    }

    const shouldComponentRender = () => {
        const {pending} = props;
        if(pending === false) return true;
        return false;
    }
    const renderMenuCategories = (menuCategories, menu) => {
        if(AppConfig.isWidget() && !AppConfig.isGrid()){
            return (
                <MenuItemsList promotions={props.promotions} preview={props.preview} categories={menuCategories} menu={menu} addItem={addItem} />
            )
        }
        return (
            <MenuItemsGrid  promotions={props.promotions} preview={props.preview} categories={menuCategories} menu={menu}  addItem={addItem} />
        )
    }
    const parseCategories = (menu, categories, items, availabilities, pick_date, promotions) => {
        let availablePromotions = PromotionHelper.getActivePromotionsForCategory(promotions, props.cart.total);
        let menuCategories = [];
        menu.categories.forEach(menuCategoryItem => {
            let category = categories.filter(categoryItem => categoryItem.id === menuCategoryItem.category_id)[0];
            if(category === undefined){
                return;
            }
            if(category.status !== 'ENABLED'){
                return;
            }
            let menuCategory = {
                id: category.id,
                position: menuCategoryItem.position === undefined ? 0 : menuCategoryItem.position,
                name: category.translation.name,
                image_link: category.image_link,
                description: category.translation.description,
                entities: []
            }
            category.entities.forEach(entity => {
                let entityItem = null;
                if(entity.type = "ITEM"){
                    let item  = items.filter(item => item.id === entity.entity_id)[0];
                    if(item == null){
                        return;
                    }
                    entityItem = ItemViewHelper.parseItem(item, availabilities, availablePromotions, pick_date, entity.position, props.cart);
                    menuCategory.entities.push(entityItem);
                }
            });
            if(itemGroups && itemGroups.length > 0){
                let newMenuCategoryEntities = [];
                menuCategory.entities.forEach(entity => {
                    let itemGroupForItem = ItemHelper.getItemGroup(itemGroups, entity.id);
                    if(!itemGroupForItem){
                        newMenuCategoryEntities.push(entity);
                        return;
                    }
                    let itemGroupView = ItemViewHelper.parseItemGroup(itemGroupForItem, items, availabilities, availablePromotions, pick_date, entity.position, props.cart);

                    let newMenuCategoryEntity = newMenuCategoryEntities.filter(x => x.reference_id === itemGroupView.reference_id)[0];
                    if(newMenuCategoryEntity !== undefined){
                        if(entity.position < newMenuCategoryEntity.position) newMenuCategoryEntity.position = entity.position;
                        return;
                    }
                    if(itemGroupView.item){
                        let itemEntity = menuCategory.entities.filter(x => x.id === itemGroupView.item.id)[0];
                        if(itemEntity !== undefined){
                            itemGroupView.item = itemEntity;
                        }else{
                            itemGroupView.item = ItemViewHelper.parseItem(itemGroupView.item, availabilities, availablePromotions, pick_date, entity.position, props.cart);
                        }
                    }
                    newMenuCategoryEntities.push(itemGroupView);
                })
                menuCategory.entities = newMenuCategoryEntities;
            }
            if(menuCategory.entities.length > 0){
                menuCategories.push(menuCategory);
            }
        });
        menuCategories = menuCategories.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name));
        return menuCategories;
    }
    const renderMenuAdvert = () => {
        const menuAdverts = props.pageComponents.filter(x => x.placement == "menu_advert");
        if(menuAdverts === undefined || !menuAdverts || menuAdverts.length === 0){
            return null;
        }
        return (
            <div className="container menu-advert">
                {menuAdverts.map(menuAdvert => {
                    return (
                        <div key={menuAdvert.id} dangerouslySetInnerHTML={{__html: menuAdvert.content}} />
                    )
                })}
            </div>
        )
    }
    const filterItems = (items) => {
        if(!search) return items;
        return items.filter(item => item.translation.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    }


    let availableMenus = menus;
    let filteredItems = filterItems(items);
    let menuView = null;
    if(!shouldComponentRender()){
        menuView = (
            <div className="loading-container loading-container-p">
                <LoadingContainer/>
            </div>
        );
    }else if(error){
        menuView= (
            <div>
                <ErrorView>
                    {error.message}
                </ErrorView>
            </div>
        );
    }else {
        let menuId = match.params.menuId !== undefined ? match.params.menuId : null;
        if(menuId === null){
            if(availableMenus !== undefined && availableMenus.length > 0){
                availableMenus = availableMenus.sort((a, b) => a.name.localeCompare(b.name));
                availableMenus.forEach(availableMenu => {
                    if(menuId !== null){
                        return;
                    }
                    if(MenuHelper.isMenuAvailable(availableMenu, orderType, pick_date, availabilities)){
                        menuId = availableMenu.id
                    }
                });

                if(menuId === null){
                    menuId = availableMenus[0].id
                }
            }
        }else{
            menuId = parseInt(match.params.menuId);
        }

        let menu = availableMenus.filter(menu => menu.id === menuId)[0];

        if(menu === undefined || !menu){

            menuView = (
                <React.Fragment>
                    <MenuMenus menus={availableMenus} menuId={menuId} search={(value) => setSearch(value)} />
                    <div className="error-loading">
                        {t("components.menu.not_exist")}
                    </div>
                </React.Fragment>
            );
        }else{
            let menuAvailableView = null;
            if(pick_date && !menu.pick_date){
                menuAvailableView = (
                    <div className="container">
                        <div className="alert alert-danger">
                            {t("components.menu.unavailable_pickup_date")}
                        </div>
                    </div>
                )
            }else{

                let changePickDateButton = null;
                if(!preview){
                    if(menu.pick_date){
                        changePickDateButton = (
                            <React.Fragment>
                                <button type="button" className="btn" onClick={() => setShowPickDateModal(true)}>
                                    {t("common.action.select_pickup_date")}
                                </button>
                                <ChangePickDateModal menuId={menu.id} pickDate={props.pick_date} showModal={showPickDateModal} onHide={() => setShowPickDateModal(false)} />
                            </React.Fragment>
                        )
                    }
                }
                let availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id);
                if(!AvailableHelper.isAvailabileDate(availability, DateService.emptyOrNow(pick_date))){
                    let pickDateText = "";
                    if(menu.pick_date){
                        pickDateText = pick_date ?  t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today_pickup_date");
                    }else{
                        pickDateText = pick_date ?  t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today");
                    }
                    menuAvailableView = (
                        <div className="container">
                            <div className="alert alert-danger">
                                {pickDateText}
                                {changePickDateButton}
                            </div>
                        </div>
                    )
                }else if(!AvailableHelper.isAvailabileHours(availability, DateService.emptyOrNow(pick_date))){
                    let todayOpenHours = ItemHelper.getTodayOpenHoursString(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pick_date));
                    let todayOpenHoursView = null;
                    if(todayOpenHours !== null){
                        todayOpenHoursView = pick_date ? t("components.menu.unavailable_with_openhours", {openHours: todayOpenHours}) : t("components.menu.available_time") + ": " + todayOpenHours
                    }else{
                        todayOpenHoursView = pick_date ? t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today");
                    }
                    menuAvailableView = (
                        <div className="container">
                            <div className="alert alert-danger">
                                {todayOpenHoursView}
                                {changePickDateButton}
                            </div>
                        </div>
                    )
                }
            }
            // let menuCategoryIds = menu.categories.map(category => category.category_id);
            let menuCategories = parseCategories(menu, categories, filteredItems, availabilities, pick_date, props.promotions)
            // let categoryIds = menuCategories.map((item, i) => "category-" + item.id);

            let stickyView = null;
            if(!AppConfig.isWidget()){
                stickyView = (
                    <Sticky>

                        {({
                              style,

                              // the following are also available but unused in this example
                              isSticky,
                              wasSticky,
                              distanceFromTop,
                              distanceFromBottom,
                              calculatedHeight
                          }) => (
                            <header className="parent-top-nav sticky-fixed sticky-fixed-categories" id="nav-scrollspy" style={style}>
                                <MenuCategoryNav promotions={props.promotions} categories={menuCategories} menu={menu} />
                            </header>
                        )}

                    </Sticky>
                );
            }else{
                stickyView = (
                    <header className="parent-top-nav sticky-fixed sticky-fixed-categories" id="nav-scrollspy">
                        <MenuCategoryNav promotions={props.promotions} categories={menuCategories} menu={menu} />
                    </header>
                );
            }
            menuView = (
                <React.Fragment>
                    <MenuPromotions promotions={props.promotions}/>
                    <MenuMenus menus={availableMenus} menuId={menuId}  search={(value) => setSearch(value)} />
                    {menuAvailableView}
                    <Languages size={"xl"}/>
                    <StickyContainer>
                        {stickyView}
                        {renderMenuAdvert()}
                        {/*<MenuVouchersCategory menu={menu} addItem={addItem} />*/}
                        <MenuPromotionsCategory menu={menu} addItem={addItem} promotions={props.promotions}/>
                        {renderMenuCategories(menuCategories, menu)}
                    </StickyContainer>
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <ScrollToTop/>
            <div className="menu-page">
                {AppConfig.cart && !preview ?
                    (
                        <CartSticky goToCart={goToCart}></CartSticky>
                    ) : ""}
                <StoreInfo/>
                {showAgeRestricted ? (
                    <>
                        <div>
                            <AgeRestrictedModal show={true} onSave={() => {setShowAgeRestricted(false)}}/>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classNames('menu body-container', {
                            'body-container-cart': AppConfig.isCart(),
                        })}>
                            {menuView}
                            <Footer/>
                        </div>
                        {!preview && (
                            <div className="btn-submit-bottom fixed-bottom btn-submit-container">
                                <CartButton goToCart={goToCart} />
                            </div>
                        )}
                        <Route path={`/menu/:menuId/add/:id`} component={MenuAddItem}/>
                        <Route path={`/test`} component={Test}/>
                    </>
                )}

            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    error: getItemsError(state.menu),
    items: getItems(state.menu),
    categories: getCategories(state.menu),
    itemGroups: getItemGroups(state.menu),
    pending: getMenuPending(state.menu),
    promotions: getPromotions(state.menu),
    menus: getMenus(state.menu),
    store: getStore(state.store),
    pageComponents: getPageComponents(state.store),
    availabilities: getAvailabilities(state.menu),
    orderType: getOrderType(state.cart),
    cart: getCart(state.cart),
    pick_date: getPickDate(state.cart),
    preview: isPreview(state.store),
    isAlcohol: isAlcohol(state.menu)
})

export default withTranslation()(connect(
    mapStateToProps
)(Menu))
